.dashboardAndhelpSupportContainer {
    margin-top: 45px !important;
    background-color: rgb(247, 247, 247);
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.help-container {
    box-shadow: 1px 1px 1px 2px rgba(0, 126, 229, 0.2);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    /* margin-bottom: 15%; */
    background-color: white;
    justify-content: center;
}
.helpSupport-Txt{
    font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  color: rgb(109 109 109);
  background-color: rgb(255,255,255);
}
.logo_img {
    /* height: 91px;
    width: 104px; */
    /* margin-top: 30px;
    margin-bottom: 30px; */
}

.iconChat {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 80px;
}

.chat_btn {
    color: white;
    background-color: rgb(22 82 240);
    border: none;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    height: 53px;
    width: 342px;
}

.or_text {
    color: black;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-align: center;
    padding-top: 30px;
}

.number_Txt {
    text-align: center !important;
    padding-left: 15px;
    font-size: 16px;
    padding-top: 3px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.phone_txt_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 30px;

}

.whatsapp-img {
    height: 30px;
    width: 38px;
    padding-right: 7px;
}

.chat-container {
    display: flex;
    justify-content: left;
    flex-direction: row;
    margin-left: 20%;
}
.triangle-left {
	width: 10px;
	height: 10px;
	border-top: 7px solid transparent;
	border-right: 13px solid rgb(0 126 229);
	border-bottom: 7px solid transparent;
    margin-top: 30px;
    margin-left: 5px;
}
.chat-txt {
    background: rgb(0 126 229);
    color: white;
    height: 81px;
    width: 275px;
    padding: 15px;
    justify-content: center;
    border-radius: 10px;
    padding-left: 10px;  
}