.dashboardAndManagemnetContainer {
    margin-top: 37px !important;
    background-color: rgb(247, 247, 247);
    padding: 8px;
  }
  
  .dashboardTabTxt {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    text-decoration: none !important;
    margin-top: 50px;
  }
  .management_Txt{
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    text-decoration: none !important;
    margin-top: 50px;
  }
  
  .jobsTabTxt {
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    margin-top: 50px;
  }
  
  .searchJobConatiner {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    padding-top: 10px;
  }
  
  .searchJobScreen {
    flex-direction: row;
    border: 1px solid rgb(223, 223, 223);
    width: 300px;
    margin-top: 7px;
    justify-content: space-between;
  }
  
  .searchIcon {
    padding-left: 3px;
  }
  
  .searchJobInput {
    padding-left: 10px;
    border: 0px solid;
    outline: none;
    height: 17px;
  }
  
  ::placeholder {
    color: rgb(197, 197, 197);
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 1;
  }
  
  .selectedOrderHistoryContainer {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.06),
      rgba(255, 255, 255, 0.76)
    );
    padding-top: 5%;
    padding-bottom: 5%;
    opacity: 1.76;
    border-right: 5px solid rgb(255, 111, 26);
    display: flex;
  }
  
  .ordertable {
    box-shadow: 2px 2px 2px 2px rgba(0, 126, 229, 0.2);
    /* border-collapse: collapse; */
    /* padding-bottom: 30px; */
    /* justify-content: center; */
    margin-Top: 20px;
    /* margin-left: 20px; */
    /* padding-right: 20px; */
    display: flex;
    /* width: 100% !important; */
        background-color: white;

   /* align-self: center; */
    /* margin-Bottom: 50px; */
  }
  
  .OrderIdTxt {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
  
  .invoicebtn {
    height: 34px;
    width: 96px;
    margin-top: 10px;
  }
  
  .checkedbtn {
    height: 19px;
    width: 19px;
    margin-left: 20px;
    margin-top: 25px;
  }
  
  .deliverTxt {
    font-size: 14px;
    color: rgb(0 197 105);
    margin-top: 25px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
  
  .orderStatusTxt {
    font-size: 14px;
    margin-top: 60px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
  
  .ordertypeTxt {
    font-size: 14px;
    /* color: rgb(0 197 105); */
    margin-top: 60px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
  
  .currencyTxt {
    font-size: 14px;
    color: rgb(165 165 165);
    margin-top: 60px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
  
  .datesTxt {
    font-size: 14px;
    color: rgb(165 165 165);
    margin-top: 25px;
    align-items: right;
    text-align: right;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
  
  .orderDetailTxt {
    font-size: 14px;
    margin-top: 5px;
    text-decoration: underline;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
  
  .rowValue {
    height: 120px;
    /* background-color: white; */
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid rgb(226 226 226) !important;
  }