.about-container{
    box-shadow: 2px 2px 2px 2px rgba(0, 126, 229, 0.2);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20px;
    background-color: white;
    justify-content: center;
    height: 100%;
    padding: 40px;
  }
  .about-heading{
    font-family: "Montserrat";
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: bold;
  }
  
  .content-about {
    margin-left: 30px;
    margin-right: 30px;
  }
  .content{
    font-family: "Montserrat";
    font-size: 15px;
    line-height: 26px;
  }