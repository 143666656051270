.sidebarTxt {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  text-decoration: none !important;
  box-shadow: none;
  padding: 10px;
  text-transform: none;
  margin: auto;
  width: 100%;
  height: 50px;
}

.managementStyle {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  text-decoration: none !important;
  box-shadow: none;
  padding: 10px;
  text-transform: none;
}

.dashboardContainer {
  color: #ffffff;
}

.selectedDashboardContainer {
  color: "#fff";
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.06),
      rgba(255, 255, 255, 0.76));
  opacity: 1.76;
  border-right: 7px solid rgb(255, 111, 26);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: "50px";
}

.card-header {
  padding: 1px !important;
  margin-right: 10px;
  width: 100%;
}

.sideBarAlign {
  /* margin-top: 10px; */
  /* padding: 2px !important; */
}

.subSideBarAlign {
  /* padding: 2px !important; */
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}