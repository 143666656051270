/* .dropDown {
    flex-direction: row;
    align-items: center;
    border-radius: 3px !important;
    justify-content: center !important;
    border: none !important;
    box-shadow: none !important;
} */
/* .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
    } */

    .form-control-lg {
        min-height: 30px;
        width: 400px;
        padding: 2px;
        font-size: 1.25rem;
        border-radius: 0px;
        margin-top: 11px;
        border: 1px solid rgb(183, 183, 183);
      }
      .bg-width {
        background-color: white !important;
        width: 400px;
        border-radius: 0px !important;
        box-shadow: none !important;
        border: 1px solid rgb(183 183 183) !important;
        margin-top: 10px !important;
      }
      .addVehicle {
        background-color: rgb(22 82 240);
        color: white;
        height: 45px;
        width: 157px;
        border: none;
        font-family: "Montserrat", sans-serif;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
      }
      
      .dropdown-Menu {
        /* width: 400px !important; */
        height: 160px !important;
      }
      
      .dropdown-toggle {
        background-color: "#fff";
        justify-content: space-between;
        text-align: start;
        display: flex;
        border-radius: 3px;
        /* width: 400px; */
        height: 40px;
        /* border: 1px solid rgb(183 183 183) !important; */
        color: rgb(197, 197, 197);
        font-size: 15px;
      }
      
      .container-input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }
      
      .saveButton {
        background-color: rgb(22 82 240);
        color: white;
        height: 45px;
        width: 150px; 
        border: none;
        font-family: "Montserrat", sans-serif;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        margin-top: 30px;
      }
      
      .icon-box {
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(183 183 183) !important;
        width: 85px;
        height: 45px;
      }
      
      .image-upload > input {
        display: none;
      }
      .image-upload {
        flex-direction: row;
        display: flex;
      }
      .label_name {
        font-size: 15px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
      }
      
      .inputIcon {
        /* width: "20px";
        height: "20px"; */
        margin-left: 20px;
        margin-top: 10px;
      }
      
      .inputText {
        font-size: 14;
        color: "black";
        width: 60px;
        height: 15px;
        margin-left: 40px;
        margin-top: 10px;
        max-width: 200px;
      }
      .dropdown-item
      {
        background-color:  rgb(255, 255, 255);
      }
  
      .addButton{
        width: 545px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
  
      .cancelBtn{
        background-color: rgb(255 72 91);
        color: white;
        height: 45px;
        width: 95px;
        border: none;
        font-family: "Montserrat", sans-serif;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        margin-top: 20px;
        margin-right: 10px;
      }
      .saveChange {
        background-color: rgb(22 82 240);
        color: white;
        height: 45px;
        width: 167px; 
        border: none;
        font-family: "Montserrat", sans-serif;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        margin-top: 20px;
      
      }
  
      .input_place{
        padding: 4px;
        margin: 5px;
      }