.add_vehicle_text{
    color: rgb(0,126 ,229);
    font-size: 15px;
  font-family: "Montserrat", sans-serif;
font-weight: 500;
margin-right: 5px;
}
.add_container{
    border-radius: 1.5em;
    border: 1px solid rgb(0,126 ,229);
    margin-left: 20px;
}
.plus_sign{
    color: rgb(0,126 ,229);
    font-size: 20px;
}
.truck-img{
    width: 80px;
        padding-top: 6px;
}
.vehicle{
    width: 80px;
}
.truck_type{
    width: 110px;
}
.truckTxt{
    color: rgb(22,82,240);
    font-size: 13px;
    font-weight: 500;
  font-family: "Montserrat", sans-serif;


}

.payload{
    width: 90px;
}
.rego_number_text{
    font-size: 13px;
    font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: rgb(23,32,63);
}
.rego{
    width: 140px;
}
.assigned_driver{
    width: 150px;
}
.dustBin{
    margin-left: 20px;
}
.modal_class {
    width: 480px !important;
}
.typeofTruck{
    font-size: 14px;
    font-weight: 600;
  color: rgb(23,32,63);
  font-family: "Montserrat", sans-serif;
 padding: 4px;
 
}
.modal-content{
    left: 30% !important;
}
.modal_text{
    font-size: 13px;
    font-weight: 400;
    color: rgb(23,32,63);
    font-family: "Montserrat", sans-serif;
    margin-right: 20px; 
    padding-top: 5px;
}
.truck-image{
    height: 100px;
    width: 140px;
    border-radius: 6px;
    margin-left: 13px;
    margin-top: 6px;
}
.weightStyle {
    width: 13px;
    height: 13px;
    
  }
  .modal-dialog {
 
    width: 560px !important;

  }
