.mainContainer {
    background-color: rgb(231, 234, 237);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.columnStyle {
    border: 1px rgb(210, 210, 210) solid;
    border-radius: 10px;
    overflow: hidden;
}

.loginForm {
    background-color: rgb(255, 255, 255);

}

.panelStyle {
    background-color: rgb(0, 118, 220);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.input {
    height: 35px;
    margin-top: 10px;
    width: '100%';
}

.buttonStyle {
    height: 30px;
    width: 100px;
    background-color: blue;
    margin-top: 20px;
}

.verticalAlign {
    vertical-align: center;
}

.rubblLogo {
    max-height: 100;
    max-width: 200;
    object-fit: "contain";
}

.manageBorderRadius {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.form-control::placeholder {
    color: rgba(100, 100, 100, 1);
}