.Bank_detail_container {
    box-shadow: 2px 2px 2px 2px rgba(0, 126, 229, 0.2);
    margin-top: 20px;
    margin-left: 10px;
    padding: 10px;
}

.BankDetail_text {
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.add_detail_text {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: rgb(87, 87, 87);
}

.labelTxt {
    background-color: white;
    color: rgb(87, 87, 87);
    font-size: 13px;
    white-space: inherit !important;
    text-align: center;
    font-family: "Montserrat";
    font-weight: 800;
    width: 66px !important;
    text-align: center;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.11);
    border: 1px solid rgb(235, 235, 237);
    position: absolute;
    height: 50px;
    justify-content: center;
}

.button_styling {
    display: flex;
    margin-right: 10px;
}

.buttonSave {
    background-color: rgb(22 82 240);
    color: white;
    height: 46px;
    width: 187px;
    border: none;
    font-family: "Montserrat", sans-serif;
    border-radius: 8px;
    font-size: 18px;
    margin-left: 8%;
    font-weight: 600;
}

.buttonCancel {
    background-color: rgb(255 72 91);
    color: white;
    height: 46px;
    width: 95px;
    border: none;
    font-family: "Montserrat", sans-serif;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
}

.input_name {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.11);
    width: 143px;
    height: 50px;
    padding-left: 5px;
    margin-left: 67px;
    margin-right: 2px !important;
    border: none;
    font-size: 15px;
}

.input_name:focus-visible {
    outline: none !important;
    border: none !important;
}

.saveButton {

    margin-right: 10px;
    background-color: rgb(197, 197, 197);
    font-family: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px;
    width: 115px;
    text-align: center;
}

.saveButton:not(:disabled) {
    cursor: pointer;
    background-color: rgb(22, 82, 240);
}

.invoice_Number {
    width: 150px;
}

.InvoiceNumberTxt {
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: rgb(23, 32, 63);
    padding-top: 10px;
    width: 150px;
}

.material_invoice {
    width: 135px;
}

.date_text {
    width: 200px;
}

.amountTxt {
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: rgb(23, 32, 63);
    padding-top: 10px;
    /* width: 200px; */
}

.bankinvoice_text {
    font-family: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: rgb(23, 32, 63);
    padding-top: 20px;
}

.status_txt {
    border-radius: 23px;
    padding-top: 6px;
    margin-top: 2px;
    height: 40px;
    justify-content: center;
    width: 83px;
    display: flex;
}

.amount_text {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
    /* width: 150px; */
}
.editDetails{
    height: 25px;
    width: 25px;
    padding-left: 5px;
   } 
   .editTxt{
    color: rgb(0 126 229);
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    left: 65%;
  }
  .edit{
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }