.dashboardAndProfileContainer {
    margin-top: 35px !important;
    background-color: rgb(247, 247, 247);
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .profileTabTxt {
    color: rgb(0 126 229);
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  .profile-Txt {
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding-top: 20px;
    padding-left: 30px;
    color: rgb(109 109 109);
  }
  .form-containers {
    box-shadow: 2px 2px 2px 3px rgba(0, 126, 229, 0.2);
    /* margin-left: 20px;
    margin-right: 40px; */
    margin-top: 30px;
    /* margin-bottom: 100%; */
    background-color: white;
    justify-content: space-evenly;
    padding-top: 15px;
    /* padding-bottom: 15px;
    padding-right: 45px; */
  }
  .squarebox1 {
    height: 60px;
    width: 60px;
    border: 1px solid rgb(183 183 183);
    margin-top: 19px;
    border-radius: 7px;
    margin-left: 45px;
    position: relative;
    right: 45%;
  }
  .editing {
    margin-left: 38px;
    margin-top: -19px;
    height: 17px;
    width: 17px;
  }
  .albumimg {
    height: 28px;
    width: 33px;
    margin-top: -13px;
  }
  .formContent-container {
    flex-direction: row !important;
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .form-content {
    flex-direction: column;
    display: flex;
  }
  .label_name {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  }
  .placeholder_name {
    width: 400px;
    border: 1px solid rgb(183 183 183);
    padding: 5px;
    margin-top: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 15px;
  }
  .button_save {
    background-color: rgb(22 82 240);
    color: white;
    height: 45px;
    width: 157px;
    border: none;
    font-family: "Montserrat", sans-serif;
    border-radius: 8px;
    font-size: 18px;
    margin-left: 10%;
    font-weight: 600;
  }
  .button_cancel {
    background-color: rgb(255 72 91);
    color: white;
    height: 45px;
    width: 95px;
    border: none;
    font-family: "Montserrat", sans-serif;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    margin-left: 31%;
  }
  .button_container {
    display: flex;
    flex-direction: row;
    margin-top: 45px;
  }
  
  .forms-containers {
    box-shadow: 2px 2px 2px 3px rgba(0, 126, 229, 0.2);
    margin-left: 20px;
    margin-right: 40px;
    margin-top: 30px;
    /* margin-bottom: 100%; */
    background-color: white;
    justify-content: space-evenly;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 45px;
  }