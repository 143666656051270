.dashboardAndProfileContainer {
  margin-top: 35px !important;
  background-color: rgb(247, 247, 247);
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.profileTabTxt {
  color: rgb(0 126 229);
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.profile-Txt {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding-top: 20px;
  padding-left: 30px;
  color: rgb(109 109 109);
  background-color: rgb(255, 255, 255);
}
.profile_Tab_Txt {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  /* padding-left: 30px; */
  color: rgb(109 109 109);
  padding-bottom: 10px;
  padding-top: 10px;
  /* background-color: rgb(255,255,255); */
}
.form-container {
  box-shadow: 2px 2px 2px 3px rgba(0, 126, 229, 0.2);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 15%;
  background-color: white;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 25px;
}
.squareBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border: 1px solid rgb(183 183 183);
  margin-top: 19px;
  border-radius: 7px;
  overflow: hidden;
}
.Editing {
  height: 17px;
  width: 17px;
  position: absolute;
  top: 15px;
  left: 60px;
}
.album-img {
  height: 60px;
    width: 60px;
    object-fit: cover;
}
.formContent-container {
  flex-direction: row !important;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.form-content {
  flex-direction: column;
  display: flex;
}
.label_name {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: rgb(23 32 63);
}
.placeholder_name {
  width: 400px;
  border: 1px solid rgb(183 183 183);
  padding: 5px;
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 15px;
}
.button {
  background-color: rgb(22 82 240);
  color: white;
  height: 45px;
  width: 157px;
  border: none;
  font-family: "Montserrat", sans-serif;
  border-radius: 8px;
  font-size: 18px;
  margin-top: 25px;
  margin-left: 61.5% !important;
  font-weight: 600;
}

.editTxt {
  color: rgb(0 126 229);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  left: 65%;
}
.editContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.editDetails {
  margin-left: 20px;
  height: 25px;
  width: 25px;
}
.edit {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
