.dashboardAndChangePasswordContainer {
    margin-top: 35px !important;
    background-color: rgb(247, 247, 247);
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.profileTabTxt{
    font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none !important;
  font-weight: 400;
}
.changePassword-Txt{
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    color: rgb(109 109 109); 
    background-color: rgb(255,255,255);
}
.passwordContainer {
    box-shadow: 2px 2px 2px 2px rgba(0, 126, 229, 0.2);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20px;
    /* margin-bottom: 15%; */
    background-color: white;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.password-content-container {
    flex-direction: row !important;
    display: flex;
    justify-content: center;
    margin-left: 30px;
    margin-top: 20px;
    /* margin-bottom: 30px; */
}

.btn-save {
    background-color: rgb(22 82 240);
    color: white;
    text-align: center;
    justify-content: center;
    padding-top: 10px;
    border: none;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    height: 45px;
    width: 157px;
    margin-bottom: 30px;
    margin-top: 30px;
}
.placeholder_name {
    width: 400px;
    border: 1px solid rgb(183 183 183);
    padding: 5px;
    margin-top: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.password-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row; 
    position: relative;   
}
.eye{
    position: absolute;
    right: 0%;
    padding-top: 13px;
    padding-right: 12px;
}