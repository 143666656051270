.dashboardAndMyLoadContainer {
    margin-top: 40px !important;
    background-color: rgb(247,247, 247);
    height: 40px !important;
    padding: 10px !important;
    align-items: center !important;
    display: flex;
}
.dashboardAndAssignedContainer{
  background-color: rgb(247,247, 247);
  height: 40px !important;
  margin-top: 40px !important;
  align-items: center !important;
  display: flex;
}
.dashboardAndInvoiceContainer{
  margin-top: 45px !important;
  background-color: rgb(247,247, 247);
  height: 40px !important;
  align-items: center !important;
  display: flex;
}
.scrollbarContainer{
  overflow-Y: scroll;
   overflow-X: hidden;
    height: 200%; 
}

.dashboardAndCancelContainer {
  background-color: rgb(247,247, 247);
  height: 40px !important;
  align-items: center !important;
  display: flex;
  margin-top: 45px !important;

}
.dashboardTabTxt {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;    
    padding-bottom: 51px;
    padding-left: 10px;
}
.dashboard_Tab_Txt{
  font-size: 15px;
    font-family: 'Montserrat', sans-serif;    
    /* padding-bottom: 51px; */
    padding-left: 5px;
}
.dashboardTab_Txt{
  font-size: 15px;
    font-family: 'Montserrat', sans-serif;    
    margin-top: 50px;  
    text-decoration: none; 
    padding-bottom: 51px;
    padding-left: 10px;
}
.load_TabTxt{
  font-size: 15px;
    font-family: 'Montserrat', sans-serif;    
    text-decoration: none; 
    padding-bottom: 51px;
    margin-top: 50px;  
    padding-left: 5px;
}
.load_Tab_Txt{
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;    
  text-decoration: none; 
  /* padding-bottom: 51px; */
  /* margin-top: 50px;   */
  padding-left: 5px;
}
.LoadTabTxt{
  font-size: 15px;
    font-family: 'Montserrat', sans-serif;    
    padding-bottom: 51px;
    padding-left: 10px;
}
.myLoadTabTxt {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;    
    font-weight: 600;
    text-decoration: underline;
    align-self: center;
}
.tabRow {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
}
.tabContainer {
    height: 40px;
    /* background-color: red; */
    flex-direction: row !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 10px !important;

}
.assignedTableTxt {
    color: black !important;
    font-size: 18px;
    align-self: center !important;
    font-weight: 800 !important;
    font-family: 'Montserrat', sans-serif;    
   margin-top: 10px;
    
}
.nav-link.active {
border-bottom: 4px solid rgb(0,126,229);
  /* border-bottom-width:2px; */
}
.completedTxt {
    color: rgb(23,32,63);
    font-size: 20px;
    text-decoration: none !important;
    align-self: center !important;
    font-weight: 500;

}
.cancelJob_txt{
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;  
  font-weight: 600;
  /* margin-left: 15px; */
  color:rgb(109,109,109);
  margin-top: 5px;

}
.cancelledJobsTxt{
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;  
  font-weight: 600;
}
.searchJobConatiner {
    flex-direction: row !important;
    justify-content: space-between !important;
    display: flex !important;
    margin-right: 30px !important;
    /* padding-left: 5px !important; */
    align-items: center;
    /* background-color: pink; */
    
}

.searchJobView {
  margin-left: -50px;
    border: 1px solid rgb(223,223,223);
    border-radius: 7px;
    width: 300px;
    justify-content: left;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-left: 10px;
    align-items: center;
    padding-bottom: 5px;
}
.searchJob{
  display: flex;
  flex-direction: row;
}
.searchIcon {
    padding-left: 3px 
}
.searchJobInput{
    padding-left: 10px;
    border: 0px solid;
    outline: none;
    height: 19px !important;
}
.searchJobCancelledScreen{
  flex-direction: row;
border: 1px solid rgb(223, 223, 223);
width: 300px;
margin-top: 3px;
margin-left: 25px;
justify-content: space-between;
height: 30px;
border-radius: 7px;
}
.tabHalfView {
    /* background-color: yellow !important; */
    /* width: 60% !important; */
    justify-content: space-between !important;
    /* align-items: center !important; */
    flex-direction: row !important;
    display: flex !important;
    /* align-items: center !important */
}
.cancelJobTxt {
    font-size: 15px;
    color: rgb(0,126,229);
    font-weight: 500;
    font-family: 'Montserrat', sans-serif; 
    padding-right: 5px; 
}
.canceljobStyle {
    width: 20px;
    height: 17px;
    padding-right: 5px;
}
.dropdownStyle {
    width: 45px;
    height: 30px;
    padding-left: 20px;
   margin-bottom: 9px;
  }
  .tabItemContainer {
    /* background-color: pink; */
    padding: 0px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex
  }
  .tabSecondItemContainer {
    /* background-color: green; */
    padding: 0px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex
  }
  .resultTxt {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;    
    font-weight: 500;
    padding-top: 20px;
    margin-left: 10px;
  }
  .buttonTrackStyle {
    width: 95px;
    height: 40px;
    margin-right: 5px;
    margin-top: 5px;
    margin-left: 10px;
    border-radius: 7px;
    border: none;
    color: white;
    font-size: 18;
    font-weight: 600;
    text-decoration: none;
  }
  .buttonInvoiceStyle{
    width: 95px;
    height: 40px;
    margin-right: 5px;
    margin-top: 5px;
    margin-left: 32px;
    border-radius: 7px;
    border: none;
    color: white;
    font-size: 18;
    font-weight: 600;
  }
.tr {
    border-color: none !important; 
    border-style: none !important;
    border-width: none !important;
    border-radius: none !important;
    border-collapse: collapse !important;
}
.row_border{
  box-shadow: 0px 0.5px 0.5px 0.5px  rgba(0,126,229,0.2) !important;
  border-radius: 10px;
  margin-top: 10px;
}
.thead {
    /* padding: 10px 10px 10px 10px !important; */
    border-radius: 20px !important;
    overflow: hidden !important;
    border-collapse: collapse !important;
}
.tr {
    border-radius: 20px !important;
}
.cancelJobsTxt {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;    
    font-weight: 800;
    color: rgb(109,109,109);
    padding-top: 10px;
}
.dustBinStyle {
    width: 18px;
    height: 23px;
    margin-top: 15px;
} 
.cancelledTxt {
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;    
    font-weight: 500;
    color: rgb(23,32,63);
} 
.orderIdTxt {
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;    
    font-weight: 500;
    text-decoration: underline;
    padding-top: 15px;
}
.orderIdContainer {
    height: 40px !important;
    /* width: 100% !important; */
    background-color: rgb(109,109,109) !important;
    align-items: center !important;
    justify-content: center !important;
    padding-left: 15px !important;
    align-content: center !important;
    padding-top: 7px !important;
}
.orderIdMainTxt {
    font-size: 20px !important;
    color: white !important;
    font-family: 'Montserrat', sans-serif !important;    
    align-self: center !important;
    text-decoration: none !important;
    /* padding-top: 10% !important; */
    font-weight: 500 !important;
    text-align: center !important;
}
.mapMainContainer {
    flex-direction: row !important;
    /* background-color: white; */
    display: flex;
    width: 100%;
    height: 200px;
    margin-top: 10px;
    justify-content: space-between;
}
.mapContainer {
    border-radius: 10px;
    width: 70%;
    height: 200px;
    margin-top: 10px !important;
    margin-left: 17px;
    box-shadow: 0px 0.5px 0.5px 0.5px  rgba(0,126,229,0.2);
}
.routeContainer {
    background-color: white;
    border-radius: 10px;
    width: 25%;
    height: 200px;
    margin-top: 10px !important;
    margin-right: 10px;
    box-shadow: 0px 0.5px 0.5px 0.5px  rgba(0,126,229,0.2);
}
.routeHeadingContainer {
    border-bottom: 1px solid rgb(183,183,183);
    height: 30px;
    align-items: center;
}
.routeTxt {
    font-size: 11px;
    color: 'rgb(23,32,63)';
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 800;    
    padding-left: 10px;
}
.routePathContainer {
    flex-direction: row;
    display: flex;
    height: 60px;
    margin-top: 5px;
    padding-left: 10px;
    /* justify-content: space-between; */

}
.routeImgStyle {
   width: 20px;
   height: 80px;
   margin-top: 5px;
}
.pickUpTxt {
    font-size: 12px;
    color: rgb(0,126,229);
    margin-left: 7px;
    padding-top: 6px;
}
.kmTxt{
   font-size: 12px;
    color: rgb(0,126,229);
    padding-top: 15px;
 text-align: left;
}
.loadingServiceTxt {
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;    
    color: black;
    font-weight: 700;
  }
.arrowStyle {
    width: 14px;
    height: 14px;
  }
.phone_Text {
    font-size: 9px;
    font-family: 'Montserrat', sans-serif;    
    font-weight: 500;
    color: black;
    padding-left: 10px !important;
  }
  .dropOffTxt {
    font-size: 12px;
    color: rgb(0,126,229);
    margin-left: 7px !important;
    padding-top: 8px;
  }
  .buttonTrackContainerStyle {
    width: 95px;
    height: 37px;
    margin-top: 10px;
    border-radius: 7px;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: 600;
    float: right;
  }
  .orderDetailMainContainer {
    flex-direction: row !important;
    background-color: white;
    display: flex;
    /* width: 100%; */
    height: 200px;
    margin-top: 10px;
    justify-content: space-between;
}
.orderDetailContainer {
    border-radius: 10px;
    width: 70%;
    height: 200px;
    margin-top: 12px !important;
    margin-left: 15px;
    box-shadow: 0px 0.5px 0.5px 0.5px  rgba(0,126,229,0.2);

}
.dropDown {
    flex-direction: row;
    align-items: center;
    border-radius: 3px !important;
    justify-content: center !important;
    border: none !important; 
    box-shadow: 0.5px 1px 1px 1px  rgba(0,0,0,0.2) !important;
}
.dropdown-toggle::after {
    display:none  !important;
    box-shadow: 0px 0.5px 0.5px 0.5px  rgba(0,126,229,0.2);

  }
  .tableFirstValue {
    font-size: 12px !important;
    color: rgb(109,109,109) !important;
    background-color: white !important;
    margin-left: 0px !important;
    border-bottom: 1px solid rgb(183,183,183) !important;
    border-collapse: separate !important;
    width: 100px !important;
    flex: 0.5 !important;
    padding-left: 0px !important;
    margin-top: 10px !important;
    height: 35px !important
  }
  .calendarStyle {
    width: 12px;
    height: 10px
  }
  .contactInfoContainer {
    flex-direction: column;
    margin-top: 10px;
  }
  .serviceTxt {
    color: rgb(109,109,109);
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;    
  font-weight: 500;
    padding-left: 32px;
  }
  .phoneServiceTxt {
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;    
    font-weight: 500;
    color: rgb(109, 109, 109);
    margin-left: 5px;
  }
  .phoneServiceStyle {
    width: 14px;
    height: 14px;
  }
  .additionalInfoMainContainer {
    flex-direction: row !important;
    background-color: white;
    display: flex;
    width: 100%;
    height: 100px;
    margin-top: 10px;
    justify-content: space-between;
  }
  .additionalInfoContainer {
    border-radius: 10px;
    width: 70%;
    height: 100px;
    margin-top: 20px !important;
    margin-left: 20px;
    box-shadow: 0px 0.5px 0.5px 0.5px  rgba(0,126,229,0.2);
  }
  .additionalInfoTxt {
    font-size: 13px;
    color: rgb(87,87,87);
    font-family: 'Montserrat', sans-serif;    
    font-weight: 400;
  }
  .deliveryTimesContainer {
    background-color: white;
    border-radius: 10px;
    width: 25%;
    /* height: 105px; */
    margin-top: 16px !important;
    margin-right: 10px;
    box-shadow: 0px 0.5px 0.5px 0.5px  rgba(0,126,229,0.2);
    flex-direction: column;
    padding-bottom: 20px;
  }
  .deliveryTimesTxt {
    color: rgb(109,109,109);
    font-size: 13px;
    padding-left: 10px;
    font-family: 'Montserrat', sans-serif;    
    font-weight: 500;
    padding-bottom: 20px !important;
  }
  .tablestyle{
    box-shadow: 0px 0.5px 0.5px 0.5px  rgba(0,126,229,0.2);

  }
  .downloadInvoiceContainer {
    width: 100%;
    height: 30px;
    background-color: white;
    margin-top: 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px  rgba(74,74,74,0.22);
    padding-left: 12px;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }
  .orderIdInvoiceTxt {
    font-size: 12px;
    color: rgb(40,97,174);
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;    

  }
  .downloadInvoiceTxt {
    font-size: 12px;
    color: rgb(0,126,229);
    font-family: 'Montserrat', sans-serif;    

  }
  .downLoadInvoiceStyle {
    width: 30px;
    height: 17px;
    padding-left: 15px;
  }
  .invoiceStyle {
    width: 189px;
    height: 67px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .taxInvoiceTxt {
    font-size: 25px;
    color: black;
    font-weight: 600;
    padding-top: 15px;
    padding-right: 30px;
    font-family: 'Montserrat', sans-serif;    

  }
  .invoiceInfo {
    flex-direction: column;
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    height: 140px;
    border-bottom: 1px solid rgb(139,139,139);
  }
  .industryTxt {
    font-size: 14px;
    font-weight: 700;
    color: rgb(0,126,229);
    font-family: 'Montserrat', sans-serif;    

  }
  .abnTxt {
    font-size: 14px;
    font-weight: 450;
    color: black;
    padding-top: 3px;
    font-family: 'Montserrat', sans-serif;    

  }
  .addressInvoiceTxt {
    font-size: 12px;
    color: black;
    padding-top: 5px;
    font-family: 'Montserrat', sans-serif;    

  }
  .emailHeadingTxt {
    font-size: 14px;
    font-weight: 450;
    color: black;
    padding-top: 3px;
    font-family: 'Montserrat', sans-serif;    

  }
  .billInfo {
    flex-direction: row;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
  }
  .billToTxt {
    font-size: 14px;
    font-weight: 450;
    color: rgb(0,126,229);
    padding-top: 3px;
    font-family: 'Montserrat', sans-serif;    

  }
  .technologyTxt {
    font-size: 14px;
    color: rgb(39,39,39);
    padding-top: 3px;
    padding-left: 10px;
    font-weight: 450;
    font-family: 'Montserrat', sans-serif;    


  }
  .addressTechTxt {
    font-size: 12px;
    color: rgb(39,39,39);
    padding-left: 10px;
    font-family: 'Montserrat', sans-serif;    

  }
  .abnTechTxt {
    font-size: 14px;
    color: rgb(39,39,39);
    padding-left: 10px;
    font-weight: 450;
    font-family: 'Montserrat', sans-serif;    


  }
  .invoiceNoTxt {
    font-size: 14px;
    color: rgb(0,126,229);
    padding-left: 10px;
    font-weight: 450;
    font-family: 'Montserrat', sans-serif;    

  }
  .invoiceValue {
    font-size: 11px;
    color: rgb(39,39,39);
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Montserrat', sans-serif;    

  }
  .dateInvoiceTxt {
    font-size: 14px;
    color: rgb(0,126,229);
    padding-left: 45px;
    font-weight: 450;
    font-family: 'Montserrat', sans-serif;    

  }
  .dateValue {
    font-size: 11px;
    color: rgb(39,39,39);
    padding-left: 7px;
    padding-right: 10px;
    font-family: 'Montserrat', sans-serif;    

  }
  .dueDateTxt {
    font-size: 14px;
    color: rgb(0,126,229);
    padding-left: 15px;
    font-weight: 450;
    font-family: 'Montserrat', sans-serif;    

  }
  .dueDateValue {
    font-size: 11px;
    color: rgb(39,39,39);
    padding-left: 9px;
    padding-right: 10px;
    font-family: 'Montserrat', sans-serif;    

  }
  .invoiceTableHeading {
    font-size: 12px;
    color: rgb(87,87,87);
    border-right: 0.5px solid rgb(139,139,139);
    font-family: 'Montserrat', sans-serif;    
  }
  .table_border_style>:not(caption)>*>*{
   padding:4px 4px !important;
   border-bottom-width: 0px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .table_border_style{
    border: 0.5px solid rgb(139,139,139) !important;
    border-radius: 10px !important;
   
    margin-right: 15px !important;
  }
  .td {
    border: 0.5px solid rgb(139,139,139) !important;
    padding: 0px !important;
    /* border-collapse: separate !important; */

}
.td1 {
    border-right: 0.5px solid rgb(139,139,139) !important;
}
.borderStyle {
    border-bottom: 0.5px solid rgb(139, 139, 139) !important;
}
.listValue {
    font-size: 12px;
    color: rgb(139,139,139); 
    font-family: 'Montserrat', sans-serif;    
   
}
.listValue1 {
    font-size: 14px;
    color: rgb(87,87,87); 
    font-family: 'Montserrat', sans-serif;    
}
.billContainer {
    flex-direction: row !important;
    justify-content: space-between !important;
    /* background-color: yellow; */
    width: 270px !important;
    height: 100px !important;
    margin-top: 10px !important;
    border-bottom: 1px solid gray;
    align-self: flex-end !important;
    flex:1 !important;
    display: flex;
    padding-right: 10px;
    margin-right: 15px;

}
.titleTxt {
    font-size: 14px;
    color: black; 
    font-weight: 500;
    padding-bottom: 8px;
    font-family: 'Montserrat', sans-serif;    

}
.valueTxt {
    font-size: 14px;
    color: black; 
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;    

}
.totalAmountContainer {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    margin-top: 7px;
}
.totalBillContainer {
    width: 270px !important;
    align-self: flex-end !important;
    margin-right: 15px;
}
.paidValueTxt {
    font-size: 14px;
    color: rgb(139,139,139); 
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;    

}
.headingTxt {
    font-size: 12px; 
    color: rgb(23,32,63);
    font-family: 'Montserrat', sans-serif;    
     font-weight: 600;
}
.react-datetime-picker__wrapper{
    border: none !important
}
.pickerStyle {
    flex-direction: row;
    justify-content: center !important;
    border: none !important; 
    box-shadow: 0px 0.5px 0.5px 0.5px  rgba(0,0,0,0.2);
    height: 20px !important;
    padding: 0 !important;
    align-items: center !important;
    align-content: center !important;
}
.materialQtyTxt {
  font-size: 12px;
  color: rgb(109,109,109);
  font-family: 'Montserrat', sans-serif;    
}
.nav-tabs .nav-link {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;

}
.color-red {
  color: red;
}

.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active.color-red {
  border-bottom: 3px solid rgb(0,126,229);
  color: rgb(23,32,63);
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;    
  font-weight: 800;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active.color-red {
  border-bottom: 3px solid rgb(0,126,229);
  color: rgb(23,32,63);
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;    
  font-weight: 800;
}
.grid_1{
  display:flex;
  margin-left: 2px;
  border-radius: 10px; 
  margin-top: 20px;
  /* justify-content: space-between; */
}
.custom-select{
  color:rgb(114, 110, 110) !important ;
  font-size: 12px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  padding: 3px !important;
  border: none!important;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.16) !important;
  border-radius: 3px !important;
}
.custom-select:focus-visible{
  outline: none !important;
  border:none !important;
}
.assignedJob_table_row{
  margin-top: 15px;
  border: none !important;
  width: 97% !important;
  margin-left: 10px !important;
}
.rowHeadings{
  background-color: rgb(109,109,109);
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
   color: white;
  padding: 7px !important;
  margin-top: 20px !important;
}
.row_shadow_1{
  box-shadow: 1px 1px 1px 1px rgba(0, 126, 229, 0.2);
  padding-left: 10px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  /* padding-right: 10px !important; */
  margin-top: 20px !important;
  background-color: rgb(255,255,255);
}
.order_text{
  width: 100px;
}
.material_text{
  width: 100px;
}
.loading_text{
width: 160px;
}
.unloading_text{
  width: 140px;
}
.delivery_text{
  width: 149px;
}
.orderTxt{
  width: 88px;
}
.orderIdText{
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: black;
  padding-top: 10px;
   width: 88px;
}